import axios from 'axios'
import { saveAs } from 'file-saver'

export function download(url, params, filename) {
  return axios.post(url, params, {
    // transformRequest: [(params) => { return tansParams(params) }],
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    headers: { isToken: false },
    responseType: 'blob'
  }).then(async (data) => {
    const isLogin = await blobValidate(data);
    if (isLogin) {
      const blob = new Blob([res.data], { type: 'application/octet-stream' })
      saveAs(blob, decodeURIComponent(res.headers['download-filename']))
      // const blob = new Blob([data])
      // saveAs(blob, filename)
      // this.$modal.msgSuccess('导出成功~')
    } else {
      // Message.error('无效的会话，或者会话已过期，请重新登录。');
    }
  }).catch((r) => {
    console.error(r)
    // Message.error('下载文件出现错误，请联系管理员！')
  })
}
